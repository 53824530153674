// import node_modules
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import smoothscroll from 'smoothscroll-polyfill';

// import components
import Footer from '../Footer';
import Head from '../Head';
import Header from '../Navbar';
import CookieNotice from '../CookieNotice';

// import styles
import { LayoutContainer, Main } from './styles';

// Layout component
const Layout = ({ children, title, description, image, pathname }) => {
  // handle data changes
  useEffect(() => {
    // NOTE: because of GATSBY build
    if (typeof window === 'undefined') return;

    // init smoothscroll polyfill
    smoothscroll.polyfill();
  });

  return (
    <LayoutContainer>
      <CookieNotice />
      <Head title={title} description={description} image={image} pathname={pathname} />
      <Header pathname={pathname} />
      <Main>{children}</Main>
      <Footer />
    </LayoutContainer>
  );
};

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default Layout;
