/* eslint-disable react/no-danger */

// import node_modules
import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

// Head component
const Head = ({ title, description, image, pathname }) => {
  // META TAGS
  const metaTags = [
    // content
    { charSet: 'utf-8' },
    { name: 'description', content: description },
    { name: 'viewport', content: 'width=device-width, initial-scale=1.0' },
    { name: 'image', content: image },
    {
      name: 'msapplication-TileImage',
      content: `https://www.silkeblume.com/ms-icon-144x144.png`,
    },
    { name: 'msapplication-TileColor', content: '#ffffff' },
    { name: 'theme-color', content: '#ffffff' },

    // prerender
    { name: 'fragment', content: '!' },

    // facebook
    { property: 'og:title', content: title },
    { property: 'og:description', content: description },
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `https://www.silkeblume.com${pathname}` },
    { property: 'og:image', content: image },
    { property: 'og:image:width', content: '1440' },
    { property: 'og:image:height', content: '900' },

    // twitter
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:creator', content: process.env.GATSBY_TWITTER_USERNAME },
    { name: 'twitter:title', content: title },
    { name: 'twitter:description', content: description },
    { name: 'twitter:image', content: image },
    { name: 'twitter:url', content: `https://www.silkeblume.com${pathname}` },

    // google tag manager
    {
      name: 'google-site-verification',
      content: process.env.GATSBY_GOOGLE_SITE_VERIFICATION,
    },
  ];

  // LINKS
  const linkTags = [
    { rel: 'canonical', href: `https://www.silkeblume.com${pathname}` },
    {
      rel: 'shortcut icon',
      type: 'image/x-icon',
      href: `https://www.silkeblume.com/favicon.ico`,
    },
    { rel: 'icon', type: 'image/x-icon', href: '/favicon.png' },
    { rel: 'apple-touch-icon', href: `https://www.silkeblume.com/apple-icon.png` },
    {
      rel: 'apple-touch-icon',
      href: `https://www.silkeblume.com/apple-icon-57x57.png`,
      sizes: '57x57',
    },
    {
      rel: 'apple-touch-icon',
      href: `https://www.silkeblume.com/apple-icon-60x60.png`,
      sizes: '60x60',
    },
    {
      rel: 'apple-touch-icon',
      href: `https://www.silkeblume.com/apple-icon-72x72.png`,
      sizes: '72x72',
    },
    {
      rel: 'apple-touch-icon',
      href: `https://www.silkeblume.com/apple-icon-76x76.png`,
      sizes: '76x76',
    },
    {
      rel: 'apple-touch-icon',
      href: `https://www.silkeblume.com/apple-icon-114x114.png`,
      sizes: '114x114',
    },
    {
      rel: 'apple-touch-icon',
      href: `https://www.silkeblume.com/apple-icon-120x120.png`,
      sizes: '120x120',
    },
    {
      rel: 'apple-touch-icon',
      href: `https://www.silkeblume.com/apple-icon-144x144.png`,
      sizes: '144x144',
    },
    {
      rel: 'apple-touch-icon',
      href: `https://www.silkeblume.com/apple-icon-152x152.png`,
      sizes: '152x152',
    },
    {
      rel: 'apple-touch-icon',
      href: `https://www.silkeblume.com/apple-icon-180x180.png`,
      sizes: '180x180',
    },
    {
      rel: 'icon',
      type: 'image/png',
      href: `https://www.silkeblume.com/favicon.png`,
      sizes: '192x192',
    },
    {
      rel: 'icon',
      type: 'image/png',
      href: `https://www.silkeblume.com/favicon-16x16.png`,
      sizes: '16x16',
    },
    {
      rel: 'icon',
      type: 'image/png',
      href: `https://www.silkeblume.com/favicon-32x32.png`,
      sizes: '32x32',
    },
    {
      rel: 'icon',
      type: 'image/png',
      href: `https://www.silkeblume.com/favicon-96x96.png`,
      sizes: '96x96',
    },
    // fonts
    {
      href: 'https://fonts.googleapis.com/css?family=Rajdhani:400,400i,700,700i&display=swap',
      rel: 'stylesheet',
    },
  ];

  // SCRIPTS
  const jsonLD = {};
  jsonLD['@content'] = 'https://schema.org/';
  jsonLD['@type'] = 'WebPage';
  jsonLD.name = title;
  jsonLD.url = `https://www.silkeblume.com${pathname}`;
  jsonLD.publisher = 'Silke Blume';
  const scriptTags = [
    {
      type: 'application/ld+json',
      innerHTML: JSON.stringify(jsonLD),
    },
  ];

  // set head via Helmet
  return (
    <Helmet
      htmlAttributes={{ lang: 'en' }}
      link={linkTags}
      meta={metaTags}
      script={scriptTags}
      title={title}
    />
  );
};

Head.propTypes = {
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default Head;
