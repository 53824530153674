// import node_modules
import React, { useEffect, useRef, useCallback } from 'react';

// CookieNotice
const CookieNotice = () => {
  // init refs
  const intervalRef = useRef();

  // init func
  const onInitCookieNotice = useCallback(() => {
    if (window && window.cookieconsent) {
      window.cookieconsent.initialise({
        palette: {
          popup: {
            background: '#1b1b1b',
            text: '#e6e7e8',
          },
          button: {
            background: '#dd709a',
            text: '#ffffff',
          },
        },
        position: 'bottom-left',
        type: 'opt-in',
        content: {
          message:
            'This website uses cookies to ensure you get the best experience on our website.',
          href: 'https://www.silkeblume.com/privacy-policy/',
        },
      });
    }
  }, []);

  // handle data changes
  useEffect(() => {
    intervalRef.current = setInterval(() => {
      if (window.cookieconsent) {
        onInitCookieNotice();
        clearInterval(intervalRef.current);
      }
    }, 1000);

    // clear on unmount
    return () => clearInterval(intervalRef.current);
  }, [onInitCookieNotice]);

  return <></>;
};

export default CookieNotice;
