// Breakpoints
const breakpoints = {
  untilSmall: '449px',
  mobile: '450px',
  untilMobile: '767px',
  tablet: '768px',
  untilTablet: '1023px',
  desktop: '1024px',
  untilDesktop: '1214px',
  widescreen: '1215px',
  untilWidescreen: '1407px',
  fullhd: '1408px',
};

export default breakpoints;
