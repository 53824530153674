// import node_modules
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';

// import content
import content from '../../content/general.json';

// import styles
import {
  NavbarBrand,
  NavbarBrandText,
  NavbarContainer,
  NavbarContentContainer,
  NavbarLogo,
  NavbarMenu,
  NavbarMenuItem,
  NavbarMenuItemText,
  NavbarMenuItems,
  NavbarMobileMenu,
  NavbarToggleButton,
} from './styles';

// Navbar component
const Navbar = ({ pathname }) => {
  // init state
  const [menuState, setMenuState] = useState(false);

  // init func
  const onToggleMenu = useCallback(() => {
    setMenuState(!menuState);
  }, [menuState]);

  const onScrollToSection = useCallback(section => {
    const element = document.getElementById(section);
    element && element.scrollIntoView({ block: 'start', behavior: 'smooth' });
    setMenuState(false);
  }, []);

  return (
    <NavbarContainer>
      <NavbarContentContainer>
        <NavbarBrand to="/">
          <NavbarLogo
            alt={content.navbar.brand.name}
            height="auto"
            src={content.navbar.brand.logo}
            width="auto"
          />

          <NavbarBrandText>Silke Blume</NavbarBrandText>
        </NavbarBrand>

        {pathname === '/' && (
          <NavbarMenu>
            <NavbarMenuItems>
              {content.navbar.items.map(item => (
                <NavbarMenuItem
                  key={item.id}
                  type="button"
                  onClick={() => onScrollToSection(item.id)}
                >
                  <NavbarMenuItemText>{item.label}</NavbarMenuItemText>
                </NavbarMenuItem>
              ))}
            </NavbarMenuItems>
          </NavbarMenu>
        )}

        {pathname === '/' && (
          <NavbarToggleButton onClick={onToggleMenu} type="button" isActive={menuState}>
            <span />
            <span />
            <span />
          </NavbarToggleButton>
        )}

        {pathname === '/' && menuState && (
          <NavbarMobileMenu>
            <NavbarMenuItems>
              {content.navbar.items.map(item => (
                <NavbarMenuItem
                  key={item.id}
                  type="button"
                  onClick={() => onScrollToSection(item.id)}
                >
                  <NavbarMenuItemText>{item.label}</NavbarMenuItemText>
                </NavbarMenuItem>
              ))}
            </NavbarMenuItems>
          </NavbarMobileMenu>
        )}
      </NavbarContentContainer>
    </NavbarContainer>
  );
};

Navbar.propTypes = {
  pathname: PropTypes.string.isRequired,
};

export default Navbar;
