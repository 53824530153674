// import node_modules
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';

// import styles
import { fadeInAnimation } from '../../styles/animations';
import breakpoints from '../../styles/breakpoints';
import colors from '../../styles/colors';
import fonts from '../../styles/fonts';
import shadows from '../../styles/shadows';
import transitions from '../../styles/transitions';

// define styles
export const NavbarContainer = styled.header`
  background-color: ${colors.white};
  box-shadow: ${shadows.default};
  height: 70px;
  width: 100vw;
  z-index: 1000;
`;

export const NavbarContentContainer = styled.div`
  align-content: center;
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  position: relative;
`;

export const NavbarBrand = styled(Link)`
  align-content: center;
  align-items: center;
  color: ${colors.dark};
  display: flex;
  font-family: ${fonts.families.default};
  font-size: ${fonts.sizes.large};
  letter-spacing: ${fonts.letterSpacings.default};
  line-height: 1;
  outline: 0;
  text-decoration: none;
  text-transform: uppercase;
  transition: all ${transitions.easeOut};

  &:focus,
  &:hover {
    color: ${colors.link};
  }
`;

export const NavbarBrandText = styled.span`
  margin: 0.25rem 0 0 0.5rem;
`;

export const NavbarLogo = styled.img`
  height: auto;
  width: 50px;
`;

export const animatedBars = css`
  &:nth-of-type(1) {
    transform: rotate(-45deg) translate(-3px, 3px);
  }

  &:nth-of-type(2) {
    opacity: 0;
  }

  &:nth-of-type(3) {
    transform: rotate(45deg) translate(-6px, -6px);
  }
`;

export const NavbarToggleButton = styled.button`
  background-color: ${colors.white};
  border: none;
  color: ${colors.dark};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-family: ${fonts.families.default};
  font-size: ${fonts.sizes.normal};
  font-weight: ${fonts.weights.regular};
  letter-spacing: ${fonts.letterSpacings.default};
  line-height: ${fonts.lineHeights.default};
  margin-left: 0.5rem;
  outline: 0;
  padding: 0.5rem 0.8rem;
  text-decoration: none;
  transition: all ${transitions.easeOut};

  span {
    background-color: ${colors.dark};
    height: 2px;
    margin: 2px 0;
    transition: all ${transitions.easeOut};
    width: 28px;

    ${({ isActive }) => isActive && animatedBars}
  }

  @media only screen and (min-width: ${breakpoints.desktop}) {
    display: none;
  }
`;

export const NavbarMenuItem = styled.button`
  background-color: ${colors.white};
  border: none;
  color: ${colors.dark};
  cursor: pointer;
  font-family: ${fonts.families.default};
  font-size: ${fonts.sizes.normal};
  font-weight: ${fonts.weights.regular};
  letter-spacing: ${fonts.letterSpacings.default};
  line-height: ${fonts.lineHeights.default};
  margin-left: 0.5rem;
  outline: 0;
  padding: 0.5rem 0.8rem;
  text-decoration: none;
  transition: all ${transitions.easeOut};

  &:focus,
  &:hover {
    color: ${colors.link};
  }
`;

export const NavbarMenuItemText = styled.span`
  font-family: ${fonts.families.default};
  font-size: ${fonts.sizes.normal};
  text-transform: uppercase;
`;

export const NavbarMenuItems = styled.div`
  display: flex;
`;

export const NavbarMenu = styled.div`
  display: flex;

  @media only screen and (max-width: ${breakpoints.desktop}) {
    display: none;
  }
`;

export const NavbarMobileMenu = styled.div`
  ${fadeInAnimation}

  background-color: ${colors.white};
  box-shadow: ${shadows.bottomOnly};
  display: none;
  padding: 1rem 0;

  @media only screen and (max-width: ${breakpoints.desktop}) {
    display: flex;
    left: 0;
    position: fixed;
    top: 69px;
    width: 100%;

    ${NavbarMenuItems} {
      flex-wrap: wrap;
      width: 100vw;
    }

    ${NavbarMenuItem} {
      margin-left: 0;
      width: 100%;
    }
  }
`;
