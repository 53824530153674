// SHADOWS

// define shadows
const shadows = {
  bottomOnly: '0 6px 10px 0 rgba(0, 0, 0, 0.14)',
  default:
    '0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.3)',
};

export default shadows;
