// Define stlyes for styled-components
import { keyframes, css } from 'styled-components';

// Animations
export const fadeIn = keyframes`
  0% { 
    opacity: 0;
  }

  90% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
`;

export const fadeOut = keyframes`
  0% {
    opacity: 1;
  }

  10% {
    opacity: 0.5;
  }

  100% {
    opacity: 0;
  }
`;

export const slideIn = keyframes`
  0% { 
    opacity: 0;
    transform: translateX(100%);
  }

  90% {
    opacity: 0.5;
    transform: translateX(10%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
`;

export const slideOut = keyframes`
  0% {
    opacity: 1;
    transform: translateX(0%);
  }

  10% {
    opacity: 0.5;
    transform: translateX(10%);
  }

  100% {
    opacity: 0;
    transform: translateX(100%);
  }
`;

export const animateGradient = keyframes`
   0% {
     background-position: 0% 0%;
   }

   100% {
     background-position: 100% 0%;
   }
`;

export const fadeInAnimation = css`
  animation-delay: 0;
  animation-direction: inherit;
  animation-duration: 200ms;
  animation-fill-mode: both;
  animation-iteration-count: 1;
  animation-name: ${fadeIn};
  animation-timing-function: ease-in;
`;

export const fadeOutAnimation = css`
  animation-delay: 0;
  animation-direction: inherit;
  animation-duration: 200ms;
  animation-fill-mode: both;
  animation-iteration-count: 1;
  animation-name: ${fadeOut};
  animation-timing-function: ease-out;
`;

export const slideInAnimation = css`
  animation-delay: 0;
  animation-direction: inherit;
  animation-duration: 200ms;
  animation-fill-mode: both;
  animation-iteration-count: 1;
  animation-name: ${slideIn};
  animation-timing-function: ease-in;
`;

export const slideOutAnimation = css`
  animation-delay: 0;
  animation-direction: inherit;
  animation-duration: 200ms;
  animation-fill-mode: both;
  animation-iteration-count: 1;
  animation-name: ${slideOut};
  animation-timing-function: ease-out;
`;

const animations = {
  fadeIn,
  fadeOut,
  fadeInAnimation,
  fadeOutAnimation,
  animateGradient,
  slideInAnimation,
  slideOutAnimation,
};

export default animations;
