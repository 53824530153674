// import node_modules
import { Link } from 'gatsby';
import React from 'react';

// import styles
import { FooterContainer, FooterInnerContainer, FooterContent } from './styles';

// declare React component
const Footer = () => (
  <FooterContainer id="footer">
    <FooterInnerContainer>
      <FooterContent>
        © Silke Blume &bull; <Link to="/legal-notice/">Legal Notice</Link> &bull;{' '}
        <Link to="/privacy-policy/">Privacy Policy</Link>
      </FooterContent>
    </FooterInnerContainer>
  </FooterContainer>
);

export default Footer;
