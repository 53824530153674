// import node_modules
import styled from 'styled-components';

// import styles
import colors from '../../styles/colors';
import { defaultTextStyle, linkStyle } from '../../styles/fonts';

// define styles
export const FooterContainer = styled.footer`
  align-content: center;
  align-items: center;
  background-color: ${colors.black};
  display: flex;
  height: 100%;
  justify-content: center;
  margin-top: auto;
  width: 100vw;
`;

export const FooterInnerContainer = styled.div`
  padding: 3rem 1.5rem 6rem;
`;

export const FooterContent = styled.div`
  ${defaultTextStyle}

  color: ${colors.light};
  font-size: 20px;
  text-align: center;

  a {
    ${linkStyle}

    color: ${colors.white};
  }
`;
